<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">卡信息</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="viewC">
				<div class="viewTitle">基本信息</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">条形码:</div>
						<el-input v-model="barCode" placeholder="请输入条形码" style="width: 200px;" @blur="handelBarCodeBlur" clearable :disabled="barCodeAbled"></el-input>
					</div>
					<el-button type="primary" style="margin-left: 12px;" @click="handleAddBarCodeClick" v-if="editMsg == null">生成条形码</el-button>
					<el-button style="margin-left: 12px;" @click="handleCancelCode" v-if="barCodeAbled && editMsg == null">取消生成</el-button>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>卡名称:</div>
						<el-input v-model="title" placeholder="请输入卡名称" style="width: 200px;" @input="handleInput"></el-input>
					</div>
					<div class="comItem">
						<div class="comItemName">简码:</div>
						<el-input v-model="simpleCode" placeholder="请输入简码" style="width: 200px;" :disabled="simpleCodeAbled"></el-input>
						<el-tooltip class="item" effect="dark" content="例:如佩玛思特，简码设置为pm就可以直接搜索到这个商品，一个商品可多个简码，输入框顿号分隔。如佩玛思特为pm、st、pmst" placement="bottom">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>卡分类:</div>
						<el-select v-model="firstClass" placeholder="请选择一级分类" style="width: 200px;" clearable @change="handleFirstClassChange">
							<el-option v-for="item in firstClassList.itemList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
						<el-select v-model="secondClass" placeholder="请选择二级分类" style="width: 200px;margin-left: 12px;" clearable v-if="firstClass != ''">
							<el-option v-for="item in secondClassList.itemList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="comTxt">(没找到分类?请<span @click="handleAddClassClick()">新增分类</span>)</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>适用品种:</div>
						<el-radio-group v-model="applyType">
							<el-radio :label="'common'">通用</el-radio>
							<el-radio :label="'cat'">猫猫</el-radio>
								<el-radio :label="'dog'">狗狗</el-radio>
								<el-radio :label="'yichong'">其他</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="viewTitle">售卖信息</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">零售价:</div>
						<el-input type="number" v-model.number="salePrice" placeholder="请输入零售价" style="width: 200px;"></el-input>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>设置次数:</div>
						<el-radio-group v-model="timesInfo.limitTimes">
							<el-radio :label="false">次数不限</el-radio>
							<el-radio :label="true">次数限制</el-radio>
						</el-radio-group>
						<el-input-number v-model="timesInfo.totalTimes" :step="1" style="margin-left: 30px;" size="small" v-if="timesInfo.limitTimes"></el-input-number>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>设置效期:</div>
						<el-radio-group v-model="daysLimit.daysLimit">
							<el-radio :label="false">效期不限</el-radio>
							<el-radio :label="true">效期限制</el-radio>
						</el-radio-group>
						<div style="display: flex;align-items: center;color: #666;font-size: 14px;margin-left: 30px;" v-if="daysLimit.daysLimit">
							<div>自购买日期至</div>
							<el-input-number v-model="daysLimit.days" :step="1" size="small"></el-input-number>
							<div>天后过期</div>
						</div>
						
					</div>
				</div>
				<div class="viewTitle">
					<div>对应服务</div>
					<el-button style="width: 110px;margin-left: 20px;" @click="dialogProduct_state = true">添加服务</el-button>
				</div>
				<el-table :data="tableData">
					<el-table-column prop="newData.productName" label="服务名称">
					</el-table-column>
					<el-table-column label="适用体重">
						<template slot-scope="scope">
							<span>{{scope.row.newData.weightDetail.minWeight}}kg-{{scope.row.newData.weightDetail.maxWeight}}kg</span>
						</template>
					</el-table-column>
					<el-table-column prop="newData.originalPrice" label="零售价">
					</el-table-column>
					<el-table-column label="操作" width="80">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="handleDeleteClick(scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="viewB">
				<el-button style="width: 110px;" v-if="editMsg != null" @click="printCustom(barCode)">打印条码</el-button>
				<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交</el-button>
			</div>
		</div>
		<div v-if="dialogTypeSetting_state">
			<dialogTypeSetting @submit="submit_dialogTypeSetting" :addType="addType" :nodeData="nodeData">
			</dialogTypeSetting>
		</div>
		<div v-if="dialogProduct_state">
			<dialogProduct @submit="submit_dialogProduct" :tree="tree" :selectedList="tableData"></dialogProduct>
		</div>
	</div>
</template>

<script>
import printJs from "../../../util/print.js";
import { pinyin } from 'pinyin-pro';
	import dialogTypeSetting from "../../../components/dialogTypeSetting.vue"
	import dialogProduct from "../../../components/dialogProduct.vue"
	export default {
		components: {
			dialogTypeSetting,
			dialogProduct
		},
		data() {
			return {
				editMsg:JSON.parse(this.$route.query.editMsg),  //为null是新增  编辑跳转信息
				barCode: '', //条码
				barCodeAbled:false,  //条码是否禁填
				title: '', //商品名称
				simpleCode: '', //简码
				simpleCodeAbled:false,  //简码是否禁用
				applyType: 'common', //适用品种
				salePrice: '', //零售价
				firstClass: '', //一级分类
				firstClassList:{},  //一级分类列表
				secondClass:'',  //二级分类
				secondClassList:{},  //二级分类列表
				addType:0,  //弹框类型
				nodeData:{},  //弹框内容
				dialogTypeSetting_state: false, //弹框状态
				tableData:[],  //表格
				dialogProduct_state:false,  //弹框
				tree:[{code:'servicetype'}],
				timesInfo:{   //次数
					limitTimes:false,
					totalTimes:0
				},
				daysLimit:{   //效期
					daysLimit:false,
					days:0
				}
			}
		},
		mounted() {
			this.getProductData()
			if(this.editMsg != null){
				this.barCode = this.editMsg.barCode;
				this.barCodeAbled = true;
				this.title = this.editMsg.title;
				this.simpleCode = this.editMsg.simpleCode;
				this.simpleCodeAbled = true;
				this.firstClass = this.editMsg.firstCategoryInfo.id;
				this.secondClass = this.editMsg.secondCategoryInfo.id;
				this.applyType = this.editMsg.applyType.id;
				this.salePrice = this.editMsg.salePrice;
				this.timesInfo = this.editMsg.timesInfo;
				this.daysLimit = this.editMsg.daysLimit;
				this.tableData = this.editMsg.cardServiceObject;
			}
		},
		methods: {
			printCustom() {
			let params = {
				code: this.barCode,
          name: this.title,
          sku:this.model,
          price:this.salePrice,
			};
			printJs.printTicketBarCode(params).then((res) => {
				this.$store.commit("set_printUrl", res);
				var hostObject = window.chrome.webview.hostObjects.customWebView2HostObject;
         hostObject.print(res);

			});

		},
			handleInput(event){
				console.log(event)
				this.simpleCode=this.toPinyin(event).replace(/\s+/g, '')
			},
			toPinyin(str){
				let pinyinArr=pinyin(str,{ pattern: 'initial' })
				return pinyinArr
			},
			//删除商品
			handleDeleteClick(index){
				let list = [...this.tableData];
				list.splice(index, 1); 
				this.tableData = list;
			},
			// 返回产品
			submit_dialogProduct(obj){
				this.tableData = obj.data;
				this.dialogProduct_state = false;
			},
			//提交
			handleSubmitClick(){
				if(this.barCode == ''){
					this.$message({
						type: 'error',
						message: '请输入条形码'
					});
					return
				}
				if(this.title == ''){
					this.$message({
						type: 'error',
						message: '请输入卡名称'
					});
					return
				}
				if(this.firstClass == ''){
					this.$message({
						type: 'error',
						message: '请选择一级分类'
					});
					return
				}
				if(this.secondClass == ''){
					this.$message({
						type: 'error',
						message: '请选择二级分类'
					});
					return
				}
				if(this.tableData.length == 0){
					this.$message({
						type: 'error',
						message: '请选择服务'
					});
					return
				}
				if(this.tableData.length > 1){
					this.$message({
						type: 'error',
						message: '只能提交一项服务'
					});
					return
				}
				let params = {
					barCode:this.barCode,
					applyType:this.applyType+'',
					title:this.title,
					simpleCode:this.simpleCode
				}
				// 零售价
				if(this.salePrice == ''){
					params.salePrice = 0
				}else{
					params.salePrice = this.salePrice
				}
				//获取1级类目参数
				this.firstClassList.itemList.map((item)=>{
					if(item.id == this.firstClass){
						params.firstCategoryInfo = {
							id:item.id,
							name:item.name,
							code:item.code
						}
					}
				})
				//获取2级类目参数
				this.secondClassList.itemList.map((item)=>{
					if(item.id == this.secondClass){
						params.secondCategoryInfo = {
							id:item.id,
							name:item.name,
							code:item.code
						}
					}
				})
				if(this.timesInfo.limitTimes == false){
					this.timesInfo.totalTimes = 0
				}
				
				if(this.daysLimit.daysLimit == false){
					this.daysLimit.days = 0
				}
				
				params.timesInfo = this.timesInfo;
				params.daysLimit = this.daysLimit;
				params.cardServiceObject = {};
				params.cardServiceObject.lifeServiceId = this.tableData[0].newData.productId;
				
				
				if(this.editMsg != null){   //编辑
					params.virtualId = this.editMsg.virtualId;
				}
				this.$http.post("/product/virtual/card/update", params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已提交'
						});
						sessionStorage.setItem('mark',1)
						this.$router.go(-1);
					}
				})
			},
			//1级类目变化
			handleFirstClassChange(){
				this.secondClass = '';
				this.secondClassList = [];
				this.firstClassList.itemList.map((item)=>{
					if(item.id == this.firstClass){
						this.secondClassList = item
					}
				})
			},
			//获取类目
			getProductData(){
				let params = {
					code:'virtualtype'
				}
				this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
					if (res.code === 0) {
						this.firstClassList = res.data;
						if(this.firstClass != ''){
							this.firstClassList.itemList.map((item)=>{
								if(item.id == this.firstClass){
									this.secondClassList = item
								}
							})
						}
					}
				})
			},
			//关闭弹框
			submit_dialogTypeSetting() {
				if(this.addType == 1 ){
					this.getProductData()
				}else if(this.addType == 2){
					this.getProductData();
				}
				this.dialogTypeSetting_state = false;
			},
			//打开弹框
			handleAddClassClick(){
				if(this.firstClass == ''){
					this.addType = 1;
					this.nodeData = this.firstClassList;
				}else{
					this.addType = 2;
					this.nodeData = this.secondClassList;
				}
				this.dialogTypeSetting_state = true;
			},
			//取消生成条码
			handleCancelCode(){
				this.barCode = '';
				this.barCodeAbled = false;
			},
			//生成条码
			handleAddBarCodeClick(){
				this.barCode = '';
				this.barCodeAbled = false;
				this.$http.post("/product/virtual/card/code/new", {barcode:this.barCode}).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已生成条码'
						});
						this.barCode = res.data;
						this.barCodeAbled = true;
					}
				})
			},
			//条码框失去焦点
			handelBarCodeBlur(){
				if(this.barCode != ''){
					this.$http.get("/product/goods/code/standard/info", {barcode:this.barCode}).then(res => {
						if (res.code === 0) {
							if(res.data.barCode == null){
								this.$message({
									type: 'error',
									message: '该条码无信息，请生成条码'
								});
								// this.barCode = '';
							}else{
								this.title = res.data.title;
							}
						}
					})
				}
			},
			//返回
			handleBackClick() {
				this.$confirm('是否返回上一页?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;

		.view {
			background: #fff;
			height: 100%;

			border-radius: 20px;
			display: flex;
			flex-direction: column;

			.viewT {
				box-sizing: border-box;
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px #F2F2F2;
				padding: 0 20px;
				justify-content: space-between;
			}

			.viewC {
				flex: 1;
				overflow-y: scroll;
				padding: 0 20px;
				padding-top: 20px;

				.viewTitle {
					padding-left: 9px;
					font-size: 16px;
					font-weight: bold;
					background: url(../../../assets/img/cangku/detailName.png) no-repeat;
					background-position: left top;
					background-size: 16px 16px;
					margin-bottom: 20px;
					display: flex;
					align-items: center;
				}

				.comView {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					font-size: 14px;

					.comItem {
						display: flex;
						align-items: center;
						height: 40px;

						.comItemName {
							width: 70px;
							text-align: right;
							margin-right: 8px;

							span {
								color: #FF3939;
							}
						}
					}

					.comTxt {
						color: #666;
						margin-left: 12px;
						span {
							color: #F77E04;
							cursor: pointer;
						}
					}
				}
			}

			.viewB {
				height: 68px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				box-sizing: border-box;
				border-top: solid 1px #F2F2F2;
			}

			.viewC::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
