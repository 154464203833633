import axios from '@/api/api.js'
import store from '../store/index.js'
import $public from './public.js'
let shopInfo = store.state.shopInfo;
export default {
	printTicketSaleOrder(obj) {
		return new Promise((resolve, reject) => {
			let total = 0;
			let list = []
			if(!$public.isNull(obj.orderItemList)){
				for(let i=0;i<obj.orderItemList.length;i++){
					total = $public.jia(total,$public.cheng(obj.orderItemList[i].originalPrice,obj.orderItemList[i].num))
					list.push({
						"name": obj.orderItemList[i].productName,
						"price": obj.orderItemList[i].price,
						"count": obj.orderItemList[i].num,
						"total": $public.cheng(obj.orderItemList[i].price,obj.orderItemList[i].num),
					})
				}
			}
			let payWay = ''
			if(!$public.isNull(obj.payInfo)&&!$public.isNull(obj.payInfo.payList)){
				let payList = obj.payInfo.payList
				for(let i=0;i<payList.length;i++){
					if(payList[i].payType==0){
						payWay += ' 现金￥'+payList[i].money
					}else if(payList[i].payType==1){
						payWay += ' 支付宝￥'+payList[i].money
					}if(payList[i].payType==2){
						payWay += ' 微信￥'+payList[i].money
					}else if(payList[i].payType==4){
						payWay += ' 余额￥'+payList[i].money
					}else if(payList[i].payType==3){
						payWay += ' pos机￥'+payList[i].money
					}else if(payList[i].payType==8){
						payWay += ' 第三方￥'+payList[i].money
					}
				}
			}
			let params = {
				"ReportName": store.state.mendian.jiezhangdanState? store.state.mendian.jiezhangdanState:"settleorder",
				"Params": [{
					"Variables": [
						{
							"name": "shopName",
							"datatype": "string",
							"value": shopInfo.name,
						},
						{
							"name": "shopTel",
							"datatype": "string",
							"value":shopInfo.tel,
						},
						{
							"name": "shopWorkTime",
							"datatype": "string",
							"value":shopInfo.businessHours,
						},
						{
							"name": "shopAddress",
							"datatype": "string",
							"value":shopInfo.address,
						},
						{
							"name": "shopLogo",
							"datatype": "image",
							"value": shopInfo.logo,
						},
						{
							"name": "shopQR",
							"datatype": "image",
							"value":  shopInfo.qrCode,
						},
						{
							"name": "orderNo",
							"datatype": "string",
							"value": obj.orderNo,
						},
						{
							"name": "orderTime",
							"datatype": "string",
							"value":$public.FTime(obj.operationInfo.create.handleTimeStamp),
						},
						{
							"name": "customerPhone",
							"datatype": "string",
							"value": obj.userInfo.userMobile,
						},
						{
							"name": "remark",
							"datatype": "string",
							"value":  obj.remark,
						},
						{
							"name": "orderTotalAmount", // 订单总金额
							"datatype": "string",
							"value":total+'',
						},
						{
							"name": "discountAmount",  // 折扣优惠
							"datatype": "string",
							"value": $public.jian(total,obj.totalPrice)+''
						},
						{
							"name": "shouldAmount",  // 应付
							"datatype": "string",
							"value":  obj.orderPrice+'',
						},
						{
							"name": "actualAmount",  // 实付
							"datatype": "string",
							"value": obj.totalPrice+'',
						},
						{
							"name": "payWay",
							"datatype": "string",
							"value":payWay,
						}
					],
					"DataObjects": [
						{
							"name": "data",
							"value":JSON.stringify(list),
						},
					]
				}],
				
			}
			axios.post('http://print.hscw8.com/print/do', params).then(response => {
				resolve(response.msg);
			}).catch(err => {
				reject(err)
			})
		})
	},
	printTicketRechargeOrder(obj) {
		return new Promise((resolve, reject) => {
			let payWay = ''
			if(!$public.isNull(obj.payInfo)&&!$public.isNull(obj.payInfo.payList)){
				let payList = obj.payInfo.payList
				for(let i=0;i<payList.length;i++){
					if(payList[i].payType==0){
						payWay += ' 现金￥'+payList[i].money
					}else if(payList[i].payType==1){
						payWay += ' 支付宝￥'+payList[i].money
					}if(payList[i].payType==2){
						payWay += ' 微信￥'+payList[i].money
					}else if(payList[i].payType==4){
						payWay += ' 余额￥'+payList[i].money
					}else if(payList[i].payType==3){
						payWay += ' pos机￥'+payList[i].money
					}else if(payList[i].payType==8){
						payWay += ' 第三方￥'+payList[i].money
					}
				}
			}
			let params = {
				"ReportName": store.state.mendian.chongzhixiaopiaoState? store.state.mendian.chongzhixiaopiaoState:"recharge",
				"Params": [{
					"Variables": [
						{
							"name": "shopName",
							"datatype": "string",
							"value": shopInfo.name,
						},
						{
							"name": "shopTel",
							"datatype": "string",
							"value":shopInfo.tel,
						},
						{
							"name": "shopWorkTime",
							"datatype": "string",
							"value":shopInfo.businessHours,
						},
						{
							"name": "shopAddress",
							"datatype": "string",
							"value":shopInfo.address,
						},
						{
							"name": "shopLogo",
							"datatype": "image",
							"value": shopInfo.logo,
						},
						{
							"name": "shopQR",
							"datatype": "image",
							"value":  shopInfo.qrCode,
						},
						{
							"name": "orderNo",
							"datatype": "string",
							"value": obj.orderNo,
						},
						{
							"name": "orderTime",
							"datatype": "string",
							"value":$public.FTime(obj.operationInfo.create.handleTimeStamp),
						},
						{
							"name": "customerPhone",
							"datatype": "string",
							"value": obj.userInfo.userMobile,
						},
						{
							"name": "remark",
							"datatype": "string",
							"value":  obj.remark,
						},
						{
							"name": "rechargeAmount", // 充值金额
							"datatype": "string",
							"value": $public.jia(obj.totalPrice,obj.giveMoney)+'',
						},
						{
							"name": "useableAmount",  // 可用余额
							"datatype": "string",
							"value": obj.payInfo.surplusMoney+'',
						},
						{
							"name": "currentGrowValue",  // 当前成长值
							"datatype": "string",
							"value": obj.payInfo.score+'',
						},
						{
							"name": "payAmount",
							"datatype": "string",
							"value": payWay,
						}
					]
				}],
			}
			axios.post('http://print.hscw8.com/print/do', params).then(response => {
				resolve(response.msg);
			}).catch(err => {
				reject(err)
			})
		})
	},
	printTicketBarCode(obj) {
		return new Promise((resolve, reject) => {
		console.log(obj,'00000000');

			let Params = []
			if(obj.sku){
				Params.push({
					"Variables":[
						{
							"name":"title",
							"datatype":"string",
							"value":obj.name+' '
						},
						{
							"name":"sku",         
							"datatype":"string",
							"value":obj.sku+''
						},
						{
							"name":"price",         
							"datatype":"string",
							"value":obj.price+''
						},
						{
							"name":"code",         
							"datatype":"string",
							"value":obj.code+''
						}
					]
				})
			}else{
				Params.push({
					"Variables":[
						{
							"name":"title",
							"datatype":"string",
							"value":obj.name+' '
						},
						{
							"name":"price",         
							"datatype":"string",
							"value":obj.price+''
						},
						{
							"name":"code",         
							"datatype":"string",
							"value":obj.code+''
						}
					]
				})
			}
			
			let params = {
				"ReportName": store.state.mendian.chanpinjiaqianState? store.state.mendian.chanpinjiaqianState:"productcode",  // productcode
				"Params":Params,
			}
			axios.post('http://print.hscw8.com/print/do', params).then(response => {
				resolve(response.msg);
			}).catch(err => {
				reject(err)
			})
		})
		
	},
	
}